// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-cases-courtly-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/cases/courtly.js" /* webpackChunkName: "component---src-pages-cases-courtly-js" */),
  "component---src-pages-cases-namedrop-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/cases/namedrop.js" /* webpackChunkName: "component---src-pages-cases-namedrop-js" */),
  "component---src-pages-cases-nexogen-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/cases/nexogen.js" /* webpackChunkName: "component---src-pages-cases-nexogen-js" */),
  "component---src-pages-cases-recart-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/cases/recart.js" /* webpackChunkName: "component---src-pages-cases-recart-js" */),
  "component---src-pages-cases-saas-story-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/cases/saas-story.js" /* webpackChunkName: "component---src-pages-cases-saas-story-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-software-development-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */),
  "component---src-pages-team-extension-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/team-extension.js" /* webpackChunkName: "component---src-pages-team-extension-js" */),
  "component---src-pages-team-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

